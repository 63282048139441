.App {
  text-align: center;
  background-color: #49745f;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #DAE4EC;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navbar{
  background-color: 
  #385144;
}

.card-deck {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 1rem 0 1rem;
  padding-bottom: 2rem;
}

.container{
  padding-bottom: 2rem;
}

.card{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.card-body{
  background-color:white;
  color: #8a9f97;
  border-radius: 0px;
  
}

.card{
  border-radius: 0px;
 
}

.card-title{
  min-height:50px;
}

.card-home{
  min-height: 120px;
  max-height: 120px;
}

.customcard {
  background-color:white;
  margin: 1rem;
  min-width: 15rem;
  max-width: 15rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s ease-in;
}

.card-review{
  background-color: 
  #8a9f97;
  margin: 1rem;
  min-width: 100rem;
  max-width: 100rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  transition: 0.2s ease-in;
}

.reviewBack{
  background-color: #8a9f97;
}

.col-md-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 25px;
  align-self: flex-start;
  padding-top: 25px;
}

.col-md-4-img{
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-img {
  width: 250px;
  height: 240px;
}

.img-thumbnail{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-dots span {
  display: inline-block;
  margin: 0 0.2em;
  animation: dot 1s infinite;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .col-md-4 {
    padding-top: 5px;
  }
}
